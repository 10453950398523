<template>
  <v-container>
    <WebsocketData :url-input="[$route.params.username+'/singleClient']">
      <template slot-scope="{outData}" v-if="outData != null">
        <l-map :zoom="7" :center="latLong(outData[$route.params.username+'/singleClient']['latitude'], outData[$route.params.username+'/singleClient']['longitude'])" style="z-index: 0;" class="map" :options="{zoomControl: false}">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <v-rotated-marker :zIndexOffset="20000" style="z-index: 3" :lat-lng="latLong(outData[$route.params.username+'/singleClient']['latitude'], outData[$route.params.username+'/singleClient']['longitude'])" :icon="icon" :rotationAngle="parseInt(radians_to_degrees(outData[$route.params.username+'/singleClient']['heading']).toFixed(2))"></v-rotated-marker>
        </l-map>
      </template>
    </WebsocketData>
  </v-container>
</template>

<script>
import WebsocketData from "../components/WebsocketData";
import {
  LCircleMarker,
  LControlAttribution,
  LIcon,
  LMap,
  LMarker,
  LPolyline,
  LPopup,
  LTileLayer,
  LTooltip
} from "vue2-leaflet";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import airplaneIcon from "@/assets/icons/airplane.png";
import L from "leaflet";

export default {
  name: "StreamMap",
  props: ['username'],
  components: {
    WebsocketData,
    LMap,
    LTileLayer,
    LMarker,
    LCircleMarker,
    LPopup,
    LTooltip,
    LControlAttribution,
    LIcon,
    LPolyline,
    'v-rotated-marker': Vue2LeafletRotatedMarker},
  data: function (){
    return {
      url:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      prefix: "",
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      icon: L.icon({
        iconUrl: airplaneIcon,
        iconSize: [36, 36],
        iconAnchor: [18, 18]
      })
    }
  },
  methods: {
    radians_to_degrees: function(radians)
    {
      let pi = Math.PI;
      return radians * (180/pi);
    },
    latLong: function(lat, long){
      return L.latLng(lat, long)
    }
  }
}
</script>

<style scoped>
  .map{
    height: 30rem;
  }
</style>
